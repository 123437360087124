import React, {useState, useCallback, Fragment} from 'react'
import {
  LegacyCard,
  FormLayout,
  InlineError,
  TextField,
  Text,
  Icon,
  LegacyStack,
  Button,
} from "@shopify/polaris"
import { DeleteIcon, ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons";
import ProductPickerModal from '../../../../components/productPickerModal'
import selectPayloadToData from '../../../../helpers/selectPayloadToData'
import selectPayloadToCEData from '../../../../helpers/selectPayloadToCEData';
import VersionColor from './versionColor';
import update from 'immutability-helper'
import styled from 'styled-components'
// import DiscountWarningBanner from '../common/discountWarningBanner/discountWarningBanner';
import DiscountCart from '../common/discountCart/discountCart';
import ProductEdit from './productEdit';
import CardDivider from '../../../../components/cardDivider';

const DeleteWrapper = styled.div`
    cursor: pointer;
`
function OfferProductsSetting(props) {
    const { host, version, shop, state, setState, productPickerShow, error, setError, productEdit, setProductEdit, currency, currencySymbol, token } = props
    const [productPickerVisible, setProductPickerVisible] = useState(false)
    const [isDisplayDiscount, setIsDisplayDiscount] = useState(false)
    const [productsEdit, setProductsEdit] = useState(true)

    const addAnotherProduct = () => {
      if(state.product && !Array.isArray(state.product)){
        const productItem = {
          product: state.product,
          variants: state.variants ? state.variants :(state.variant ? [state.variant]:[]),
          title: state.title,
          subtitle: state.subtitle,
          message: state.message,
          compareAtPrice: state.compareAtPrice && state.compareAtPrice?.sym ? state.compareAtPrice : {sym: '$', value:state.compareAtPrice}
        }
        setState(update(state, {
          product: { $set: [productItem, {}] },
        }))
        setProductEdit(1)
      }else{
        const productSize = state.product.length
        setState(update(state,{product:{$push: [{}]}}))
        setProductEdit(productSize)
      }
    }
    const deleteOneProduct = (index) => {
      if (state.product.length === 2 && 'tiles' === state.generalLayout && 'row' === state.layout) {
        setState(update(state, { product: { $splice: [[index, 1]]}, layout: { $set: 'carousel' }, generalLayout: { $set: 'standard' }}))
      }else{
        setState(update(state, { product: { $splice: [[index, 1]]}}))
      }
    }

    const moveItem = (from, to) => {
        const data = state.product
        const f = data.splice(from, 1)[0]
        data.splice(to, 0, f)
      //setState(update(state, { product: { $splice: [[from, 1]]}}))
      setState(update(state, { product: { $set: [...data] }}))

    }
    const moveUp = (index) => {
      moveItem(index, index-1)
    }
    const moveDown = (index) => {
      moveItem(index, index+1)
    }
    return (
      productsEdit?
      <LegacyCard sectioned>
          <div onClick={() => setProductsEdit(false)}>
          <LegacyStack alignment="center">
              <LegacyStack.Item fill>
                <Text as="h2" variant="headingMd">Product</Text>
                  <Text tone="subdued">
                    Product Title, Product Subtitle, Marketing Message, Compare at Price
                  </Text>
              </LegacyStack.Item>
              <LegacyStack.Item>
                  <Icon source={ChevronUpIcon} />
              </LegacyStack.Item>
          </LegacyStack>
          </div>
        <CardDivider/>
        <div style={{marginTop: '10px'}}>
          {Array.isArray(state.product) ?
          <div>
          {state.product.map((p,index) => {
          return(  productEdit == index?
          <ProductEdit key={index} index={index} p={p} state={state} setState={setState} currency={currency} currencySymbol={currencySymbol} token={token} shop={shop} host={host} productPickerShow={productPickerShow} error={error} setError={setError} version={version} setProductEdit={setProductEdit}/>
          :
          <VersionColor>
          <div className={version ? ("A"===version?'version-a':'version-b'):''}>
          <LegacyCard sectioned key={index}>
              <LegacyStack alignment="center">
                <LegacyStack.Item fill>
                  <LegacyStack alignment="center">
                  <img className="upsellplus-image" src={  p.product && p.product.image || 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b'} style={{width: '20px', height: '20px'}} />
                  <Text as="h3" variant="headingSm">product {index+1} : {p.product ? p.product.title: "please choose product"}</Text>
                  </LegacyStack>
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <LegacyStack alignment="center">
                    <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'center'}}>
                    <div style={{border: '1px solid #C9CCCF', borderRadius: '5px 0 0 5px', borderRight: 'none', padding: '3px 1px', cursor: 'pointer'}} onClick={() => { if(index > 0) {moveUp(index)} }}>
                      <Icon
                        source={ChevronUpIcon}
                        tone={index > 0 ? "base": "subdued"} />
                    </div>
                    <div style={{border: '1px solid #C9CCCF', borderRadius: '0 5px 5px 0', padding: '3px 1px', cursor: 'pointer'}} onClick={() => { if(index < state.product.length-1){ moveDown(index) } }}>
                      <Icon
                        source={ChevronDownIcon}
                        tone={index < state.product.length-1 ? "base": "subdued" } />
                    </div>
                  </div>
                  <Button variant="plain" onClick={()=>{setProductEdit(index); setIsDisplayDiscount(false)}}>
                    Edit
                  </Button>
                  <DeleteWrapper onClick={()=>deleteOneProduct(index)}>
                    <Button icon={DeleteIcon} tone="critical"></Button>
                  </DeleteWrapper>
                  </LegacyStack>
                </LegacyStack.Item>
              </LegacyStack>
            </LegacyCard>
          </div>
          </VersionColor>
          )
          })
        }
        <div style={{marginTop: '20px'}} className='another-product-step'>
            <Button
              onClick={addAnotherProduct}
              variant="primary">+ {state.product.length > 0 ? 'Add another product':'Add a product'}</Button>
            {
              error && error.product ?
              <div style={{marginTop:'15px'}}>
                <InlineError message={error.product} fieldID="product-error" />
              </div>
               :
              false
            }
          </div>
            </div>
          :
          <div>
          <VersionColor>
          <div className={version ? ("A"===version?'version-a':'version-b'):''}>
          <LegacyCard sectioned>
            <Text as="h3" variant="headingSm">product 1</Text>
            <FormLayout>
                <Fragment>
                    <div className='mockInputContainer'>
                      <p className='mockInputLabel' style={{paddingTop:'10px'}}>Offer product</p>
                      <div
                        className='mockInput'
                        onClick={() => setProductPickerVisible(true)}
                      >
                        {
                          ((!state.variant && !state.variants) || !state.product) ?
                            <span>Select a product</span> :
                            <div>
                              <img
                                src={state.product.image}
                                className='mockInputImage'
                              />
                              {state.variants ?
                                <span>{state.product.title} | {state.variants.map(variant => variant.title).join("/")}</span>
                                :
                                <span>{state.product.title} | {state.variant.title}</span>
                              }
                            </div>
                        }
                      </div>
                      {
                        error && error.product ?
                        <InlineError message={error.product} fieldID="product-error" />
                        :
                        false
                      }
                    </div>
                    { "product" === productPickerShow ?
                    <ProductPickerModal
                      shop={shop}
                      host={host}
                      token={token}
                      open={productPickerVisible}
                      onSelect={(selectPayload) => {
                        let data
                        if("extension" !== state.checkoutType){
                          data = selectPayloadToData(selectPayload)
                        }else{
                          data = selectPayloadToCEData(selectPayload)
                        }
                        if (!data) {
                          if (state.product) {
                            // Clear selection if one exists
                            setState(update(state, {
                              product: { $set: null },
                              variant: { $set: null },
                              variants: { $set: null },
                            }))
                          }
                          return
                        }

                        setState(update(state, {
                          product: { $set: data.product },
                          variants: { $set: data.variants },
                        }))
                      }}
                      onClose={() => setProductPickerVisible(false)}
                      initialProduct={state?.product ? {product:state.product, variants: state.variants}:null || null}
                    />
                    :
                    false
                    }
                  </Fragment>
                  <TextField
                    label="Offer Product Title"
                    placeholder="Breath Mints"
                    onChange={(val) => setState(update(state, { title: { $set: val }})) }
                    value={state.title}
                  />
                  <TextField
                    label="Offer Product Subtitle"
                    placeholder="Add on mints for 15% off!"
                    onChange={(val) => setState(update(state, { subtitle: { $set: val }})) }
                    value={state.subtitle}
                  />
                  <CardDivider />
                  <TextField
                    label="Marketing message"
                    placeholder="Get this exclusive deal now!"
                    onChange={(val) => setState(update(state, { message: { $set: val }})) }
                    value={state.message}
                  />
                  <CardDivider />
                  <LegacyCard sectioned>
                    <DiscountCart state={state} setState={setState} currency={currency} currencySymbol={currencySymbol}/>
                  </LegacyCard>
                </FormLayout>
          </LegacyCard>
          </div>
          </VersionColor>
          <div style={{marginTop: '20px'}}>
            <Button
              onClick={addAnotherProduct}
              primary>+ Add another product</Button>
          </div>
          </div>
          }
          </div>
        </LegacyCard>
        :
        <LegacyCard sectioned>
        <div onClick={() => setProductsEdit(true)}>
          <LegacyStack alignment="center">
              <LegacyStack.Item fill>
                <Text as="h2" variant="headingMd">Product</Text>
                  <Text tone="subdued">
                    Product Title, Product Subtitle, Marketing Message, Compare at Price
                  </Text>
              </LegacyStack.Item>
              <LegacyStack.Item>
                  <Icon source={ChevronDownIcon} />
              </LegacyStack.Item>
          </LegacyStack>
          </div>
        </LegacyCard>
    )
}

export default OfferProductsSetting;